<template>
    <div>
        <div
            v-if="!isLoaded"
            class="custom-loader container flex justify-content-center align-items-center"
            style="margin-top: 25%"
        />
        <div v-if="!isCompanyCompleted && isLoaded" class="container profil">
            <AccountAddress :user-profile="user" @saved="$router.go()">
                <template v-slot:title> Complétez vos informations personnelles</template>
            </AccountAddress>
        </div>
        <div v-if="isLoaded && isCompanyCompleted" class="container d-flex flex-column">
            <div class="container rounded shadow-sm bg-white p-3 d-flex flex-column">
                <h2>{{ $t('achat-credits.wantedamount') }}</h2>
                <div class="container d-flex s-flex-column">
                    <div class="mt-3 container">
                        <label for="" class="form-label">{{ $t('achat-credits.amount-title') }}</label>
                        <div class="d-flex">
                            <input id="inputPrice" v-model="amountHT" type="number" class="form-control w-50" />
                            <p class="ml-2" style="font-size: 24px">€ (HT)</p>
                        </div>
                    </div>
                    <div v-show="hasActiveCampaign" class="mt-3 container">
                        <label for="" class="form-label">Mes accompagnements</label>
                        <div class="d-flex">
                            <select
                                v-model="infos.accompagnementId"
                                type="number"
                                class="form-control w-100"
                                @change="checkAccompagnement"
                            >
                                <option value="" selected="selected" hidden disabled>
                                    Choisir un accompagnement à régler
                                </option>
                                <option value="">Pas d'accompagnement (Faire un achat de crédit classique)</option>
                                <option v-for="i in invoices" :key="i.id" :value="JSON.parse(i.infos).accompagnementId">
                                    {{ i.url }} -
                                    {{
                                        new Date(i.formatted_infos.period).toLocaleDateString('fr-fr', {
                                            month: 'long',
                                            year: 'numeric'
                                        })
                                    }}
                                </option>
                            </select>
                            <a
                                v-if="infos.accompagnementId != null && infos.accompagnementId !== ''"
                                :href="`${appUrl}/invoicepdf.php?num=${this.quotationId}`"
                                class="btn btn-warning mx-3"
                            >
                                <i class="fas fa-file-invoice" />
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <h3 class="w-100 text-center">
                    {{ $t('achat-credits.recap') }}
                </h3>
                <p class="w-100 text-center text-gray-400">
                    {{ $t('achat-credits.payment-methods') }}
                </p>
                <p v-if="amountHT < 50" class="alert alert-danger">{{ $t('achat-credits.minimumamount') }} 50€</p>
                <div v-if="amountHT >= 50" class="container d-flex align-items-start">
                    <div class="container pl-3 mt-3 w-100 d-flex flex-column align-items-center">
                        <table class="table table-striped">
                            <tr class="table-primary">
                                <td>{{ $t('achat-credits.designation-title') }}</td>
                                <td>{{ $t('achat-credits.amount-title') }}</td>
                            </tr>
                            <tr>
                                <td>HT</td>
                                <td>{{ amountHT }}€</td>
                            </tr>
                            <tr>
                                <td>TVA - {{ tva }}%</td>
                                <td>{{ computeTVAAmount }}€</td>
                            </tr>
                            <tr class="table-secondary">
                                <td>TTC</td>
                                <td>{{ computeTTC }}€</td>
                            </tr>
                        </table>

                        <a v-if="amountHT >= 50" class="btn btn-secondary w-25 text-center" @click="createNewOrder()">
                            {{ $t('achat-credits.payment-card') }}
                        </a>
                    </div>
                    <div class="container w-50 d-flex flex-column align-items-center">
                        <div v-if="displayCoupons" class="d-flex flex-column align-items-center w">
                            <p class="mt-3 mb-0 text-center">
                                {{ $t('achat-credits.promocodeenter') }}
                            </p>
                            <div class="container rounded">
                                <div class="d-flex w-100 align-items-center mt-2 justify-content-center">
                                    <input v-model="coupon.code" type="text" class="form-control w-75" />
                                    <a href="#" class="btn btn-secondary my-1 ml-2" @click="checkCoupon()">{{
                                        $t('achat-credits.apply')
                                    }}</a>
                                </div>
                            </div>
                        </div>
                        <div v-if="coupon.value > 0 && coupon.id != null" class="alert alert-success text-center w-75">
                            {{ $t('achat-credits.promocodeuse') }} {{ coupon.code }} ({{ coupon.value
                            }}{{ $t('achat-credits.creditgain') }})
                        </div>
                        <div v-if="coupon.invalide === true" class="alert alert-danger text-center w-75">
                            {{ $t('achat-credits.promocodenotvalid') }}
                        </div>
                        <p class="mb-1 mt-3">
                            {{ $t('achat-credits.additional-info') }}
                        </p>
                        <textarea
                            v-model="infos.moreInfos"
                            type="text"
                            :placeholder="$t('achat-credits.useful-reference')"
                            class="form-control w-75 mt-2"
                        />
                    </div>
                </div>
                <form
                    id="paymentForm"
                    action="https://sogecommerce.societegenerale.eu/vads-payment/"
                    method="POST"
                    class="text-center"
                >
                    <input type="hidden" name="signature" :value="computeSignature" />
                    <input type="hidden" name="vads_action_mode" :value="vadsParams.action_mode" />
                    <input type="hidden" name="vads_amount" :value="String(computeTTCForSOGE)" />
                    <input type="hidden" name="vads_ctx_mode" :value="vadsParams.ctx_mode" />
                    <input type="hidden" name="vads_currency" :value="vadsParams.currency" />
                    <input type="hidden" name="vads_page_action" :value="vadsParams.page_action" />
                    <input type="hidden" name="vads_payment_config" :value="vadsParams.payment_config" />
                    <input type="hidden" name="vads_site_id" :value="vadsParams.site_id" />
                    <input
                        type="hidden"
                        name="vads_trans_date"
                        :value="new Date().toISOString().split('.')[0].replace(/[^\d]/gi, '')"
                    />
                    <input type="hidden" name="vads_trans_id" :value="vadsParams.trans_id" />
                    <input type="hidden" name="vads_version" :value="vadsParams.version" />
                    <input type="hidden" name="vads_cust_email" :value="user.email" />
                    <input type="hidden" name="vads_cust_first_name" :value="user.firstname" />
                    <input type="hidden" name="vads_cust_last_name" :value="user.lastname" />
                    <input type="hidden" name="vads_return_mode" :value="vadsParams.return_mode" />
                    <input type="hidden" name="vads_order_id" :value="vadsParams.order_id" />
                </form>
            </div>
            <a class="btn btn-secondary my-2" @click="bankModal = !bankModal">{{
                $t('achat-credits.payment-transfer-preference')
            }}</a>
            <div v-if="bankModal" id="bankModalContainer" class="container">
                <p class="mx-6">{{ $t('achat-credits.payment-transferdescription') }} :</p>
                <div class="container d-flex">
                    <div class="container rounded shadow-sm bg-white p-3 mx-3 my-2">
                        <p class="fw-bolder">{{ $t('user.payment.rib') }} :</p>
                        <p>
                            {{ $t('user.payment.bank') }} : 30003 <br />{{ $t('user.payment.bankdepot') }} : 01174
                            <br />{{ $t('user.payment.accountnumber') }} : 00027000607 <br />{{
                                $t('user.payment.key')
                            }}
                            : 59
                        </p>
                        <p>IBAN : FR76 3000 3011 7400 0270 0060 759</p>
                        <p>BIC : SOGEFRPP</p>
                    </div>
                    <div class="container rounded shadow-sm bg-white p-3 mx-3 my-2">
                        <p class="fw-bolder">{{ $t('user.payment.bankadress') }}:</p>
                        <p>SG ANDREZIEUX LOIRE ENT (01174)<br />1 RPT AUGUSTE COLONNA<br />42160 ANDREZIEUX-BOUTHEON</p>
                        <p>
                            {{ $t('user.payment.bankaccountowner') }} : <br />KORLEON'BIZ <br />428 Route du cimetière
                            <br />42110 Poncins
                        </p>
                    </div>
                </div>
                <div class="container form-check m-3 d-flex flex-column align-items-center">
                    <div v-if="amountHT >= 50" class="d-flex">
                        <input
                            id="flexCheckDefault"
                            v-model="bankButton"
                            class="form-check-input"
                            type="checkbox"
                            value=""
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                            {{ $t('achat-credits.validation') }}
                            <strong>{{ computeTTC }} TTC €.</strong>
                            {{ $t('achat-credits.validation_02') }}
                        </label>
                    </div>

                    <button
                        v-if="bankButton && amountHT >= 50"
                        class="btn btn-primary w-25 text-center mt-2"
                        @click="createNewWire()"
                    >
                        {{ $t('achat-credits.payment-transfer') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import cryptoJS from 'crypto-js'
    import AccountAddress from '@/components/Profil/AccountAddress.vue'
    import PaypalButton from "@/components/Invoices/PaypalButton.vue";

    export default {
        title: 'credits.buy',
        components: {
            PaypalButton,
            AccountAddress
        },
        data() {
            return {
                appUrl: process.env.VUE_APP_URL,
                dataLoaded: false,
                isCompanyCompleted: false,
                isLoaded: false,
                isEnabled: true,
                bankModal: false,
                promoModal: false,
                bankButton: false,
                amountHT: 0,
                tva: 0,
                invoices: null,
                codePromo: {
                    name: '',
                    value: 0
                },
                signature: null,
                id: 0,
                orders: null,
                vadsParams: {
                    action_mode: 'INTERACTIVE',
                    ctx_mode: process.env.VUE_APP_CTX_MODE,
                    currency: '978',
                    page_action: 'PAYMENT',
                    payment_config: 'SINGLE',
                    site_id: process.env.VUE_APP_SITE_ID,
                    trans_id: '',
                    version: 'V2',
                    order_id: '',
                    return_mode: 'GET'
                },
                sogeKey: process.env.VUE_APP_SOGE_KEY,
                infos: {
                    accompagnementId: '',
                    period: '',
                    moreInfos: ''
                },
                quotationId: null,
                updateUserButton: false,
                coupon: {
                    code: null,
                    id: null,
                    value: null,
                    invalide: false
                }
            }
        },
        async mounted() {
            this.vadsParams.trans_id = this.generateUUID()
        },
        async created() {
            await this.getUserInfos()
            await this.getData()
            this.isCompanyCompleted = !!(
                this.user &&
                this.user.ville != null &&
                this.user.address != null &&
                this.user.cp != null &&
                this.user.country != null
            )
            this.computeTVA()
            this.isLoaded = true
        },
        computed: {
            ...mapGetters('auth', { user: 'user' }),
            computeSignature() {
                let message =
                    this.vadsParams.action_mode +
                    '+' +
                    String(this.computeTTCForSOGE) +
                    '+' +
                    this.vadsParams.ctx_mode +
                    '+' +
                    this.vadsParams.currency +
                    '+' +
                    this.user.email +
                    '+' +
                    this.user.firstname +
                    '+' +
                    this.user.lastname +
                    '+' +
                    this.vadsParams.order_id +
                    '+' +
                    this.vadsParams.page_action +
                    '+' +
                    this.vadsParams.payment_config +
                    '+' +
                    this.vadsParams.return_mode +
                    '+' +
                    this.vadsParams.site_id +
                    '+' +
                    new Date().toISOString().split('.')[0].replace(/[^\d]/gi, '') +
                    '+' +
                    this.vadsParams.trans_id +
                    '+' +
                    this.vadsParams.version +
                    '+' +
                    this.sogeKey

                let hash = cryptoJS.HmacSHA256(message, this.sogeKey)

                return cryptoJS.enc.Base64.stringify(hash)
            },
            computeTTC() {
                let totalTva = this.computeTVAAmount
                return (this.computeHT() * 100 + totalTva * 100) / 100
            },
            computeTVAAmount() {
                return (this.amountHT * this.tva) / 100
            },
            computeTTCForSOGE() {
                return Math.round(this.computeTTC * 100)
            },
            hasActiveCampaign() {
                return this.invoices.length > 0
            },
            displayCoupons() {
                return this.id == null || this.id === '' || this.id === 0
            }
        },
        methods: {
            ...mapActions('auth', { getUserInfos: 'me' }),
            ...mapActions('auth', { getInfos: 'getInfos' }),
            ...mapActions('auth', { updateUserInfos: 'update' }),
            ...mapActions('invoices', { createOrder: 'create' }),
            ...mapActions('invoices', {
                list: 'list'
            }),
            updateUser() {
                this.updateUserButton = true
                this.updateUserInfos(this.user).then(() => {
                    this.$router.go()
                })
            },
            getData: async function () {
                await this.list().then(data => {
                    this.invoices = data.data
                })
                this.invoices = await this.invoices.filter(
                    e =>
                        e.category_order === 'accompagnement' &&
                        e.payment_status === 'pending' &&
                        e.accompagnement !== null
                )
                this.invoices.forEach(e => {
                    if (e.accompagnement.url !== null) {
                        e.url = new URL(e.accompagnement.url).hostname
                    }
                })
            },
            generateUUID() {
                return 'xxxxxx'.replace(/[xy]/g, function (c) {
                    let r = (Math.random() * 16) | 0,
                        v = c === 'x' ? r : (r & 0x3) | 0x8
                    return v.toString(16)
                })
            },
            checkAccompagnement() {
                if (this.infos.accompagnementId != null && this.infos.accompagnementId !== '') {
                    this.amountHT = this.invoices.find(
                        e => JSON.parse(e.infos).accompagnementId === this.infos.accompagnementId
                    ).price_ht
                    this.id = this.invoices.find(
                        e => JSON.parse(e.infos).accompagnementId === this.infos.accompagnementId
                    ).id
                    this.infos.period = JSON.parse(
                        this.invoices.find(e => JSON.parse(e.infos).accompagnementId === this.infos.accompagnementId)
                            .infos
                    ).period
                    this.quotationId = this.invoices.find(
                        e => JSON.parse(e.infos).accompagnementId === this.infos.accompagnementId
                    ).id
                    document.getElementById('inputPrice').disabled = true
                } else if (this.infos.accompagnementId === '') {
                    this.quotationId = null
                    this.amountHT = 0
                    this.id = null
                    this.infos.period = null
                    document.getElementById('inputPrice').disabled = false
                }
            },
            computeTVA() {
                switch (this.user.country) {
                    case 'FR':
                        this.tva = 20
                        break
                    case 'MQ':
                    case 'RE':
                    case 'GP':
                        this.tva = 8.5
                        break
                    default:
                        this.tva = 0
                        break
                }
            },
            checkCoupon() {
                this.coupon.value = null
                this.coupon.id = null
                this.coupon.invalide = false
                this.axios
                    .get(`/orders/invoices/check-coupon/${this.coupon.code}`)
                    .then(res => {
                        this.coupon.value = res.data.coupon.reduction
                        this.coupon.id = res.data.coupon.id
                    })
                    .catch(() => {
                        this.coupon.invalide = true
                    })
            },
            async createNewOrder() {
                let params = {
                    id: Number(this.id),
                    price_ht: Number(this.amountHT),
                    price_ttc: this.computeTTC,
                    tva_percent: this.tva,
                    category_order: 'default',
                    payment_id: this.vadsParams.trans_id,
                    type_order: 'card',
                    infos: JSON.stringify(this.infos),
                    coupon: this.coupon.code
                }
                let data = await this.createOrder(params)
                await (this.vadsParams.order_id = String(data.order.id))

                document.getElementById('paymentForm').submit()
            },
            async createNewWire() {
                let params = {
                    id: Number(this.id),
                    price_ht: Number(this.amountHT),
                    price_ttc: this.computeTTC,
                    tva_percent: this.tva,
                    category_order: 'default',
                    payment_id: this.vadsParams.trans_id,
                    type_order: 'wire',
                    infos: JSON.stringify(this.infos),
                    coupon: this.coupon.code
                }
                await this.createOrder(params)
                window.location.href = '/buy-credits/redirect'
            },

            computeHT() {
                this.amountHT = parseInt(this.amountHT * 100) / 100

                return this.amountHT
            }
        }
    }
</script>

<style lang="scss" scoped>
    hr {
        border: 0;
        margin: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    @media screen and (max-width: 960px) {
        #paiment_header {
            flex-flow: column;
        }
    }
</style>
